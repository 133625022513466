import { addUtmTrackerParam } from '@ifixit/analytics';
import { useAuthenticatedUser } from '@ifixit/auth-sdk';
import { useCart } from './use-cart';
import { useAppContext } from '@ifixit/app';
import { useShopifyStorefrontClient } from '@ifixit/shopify-storefront-client';

type Checkout = {
   checkoutUrl: string | undefined;
};

export function useCheckout(): Checkout {
   const { ifixitOrigin } = useAppContext();
   const user = useAuthenticatedUser();
   const cartQuery = useCart();
   const { shopDomain } = useShopifyStorefrontClient();
   if (!cartQuery.data?.checkoutUrl) {
      return { checkoutUrl: undefined };
   }
   const checkoutUrl = new URL(cartQuery.data.checkoutUrl);
   addUtmTrackerParam(checkoutUrl);
   const shopName = shopDomain.replace('.myshopify.com', '');
   const ssoRoute = `${ifixitOrigin}/User/sso/shopify/${shopName}?checkout=1`;
   const ssoUrl = new URL(ssoRoute);
   ssoUrl.searchParams.set('return_to', checkoutUrl.toString());
   const isLoggedIn = Boolean(user.data);
   return {
      checkoutUrl: isLoggedIn ? ssoUrl.toString() : checkoutUrl.toString(),
   };
}
