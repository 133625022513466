import { createContext, useMemo, useContext } from 'react';
import type { PropsWithChildren } from 'react';

export type AppContext = {
   ifixitOrigin: string;
   adminMessage?: string;
   priceTier: string | null;
};

const AppContext = createContext<AppContext | null>(null);

const { Provider } = AppContext;

type AppProviderProps = PropsWithChildren<{
   ifixitOrigin: string;
   adminMessage?: string;
   priceTier: string | null;
}>;

export function AppProvider({ ifixitOrigin, adminMessage, priceTier, children }: AppProviderProps) {
   const value = useMemo(
      (): AppContext => ({
         ifixitOrigin,
         adminMessage,
         priceTier,
      }),
      [ifixitOrigin, adminMessage, priceTier]
   );

   return <Provider value={value}>{children}</Provider>;
}

export function useAppContext() {
   const context = useContext(AppContext);
   if (context === null) {
      // eslint-disable-next-line @ifixit/no-new-error
      throw new Error('useAppContext must be used within a AppProvider');
   }
   return context;
}

export function usePriceTier() {
   const { priceTier } = useAppContext();
   return {
      priceTier,
      isPro: Boolean(priceTier?.match(/pro/i)),
   };
}
