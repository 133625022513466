import { useToast } from '@chakra-ui/react';
import {
   convertCartLineItemsToAnalyticsItem,
   trackInAnalyticsAddToCart,
   trackPiwikCartUpdate,
} from '@ifixit/analytics';
import { StorefrontClient, useShopifyStorefrontClient } from '@ifixit/shopify-storefront-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
   convertAddToCartInputToAnalyticsItemEvent,
   trackPiwikShopifyAddToCart,
} from '../../helpers/analytics';
import { addToCart, getCart, updateCartLines } from '../../helpers/storefront-api';
import { buildIfixitCart } from '../../models/cart';
import type { Cart, CartLineItem } from '../../types';
import { cartKeys } from '../../utils';
import { useCreateCart } from './use-create-cart';

type AddToCartAnalytics =
   | {
        type: 'product';
        currentItemCode: string;
     }
   | {
        type: 'buy-it-again';
     };

export type AddToCartInput = {
   lines: CartLineItem[];
   analytics: AddToCartAnalytics;
};

export function useAddToCart(analyticsMessage?: string) {
   const createCart = useCreateCart();
   const queryClient = useQueryClient();
   const { client: storefrontClient, currencyCode } = useShopifyStorefrontClient();
   const toast = useToast();
   const mutation = useMutation(
      async ({ lines }: AddToCartInput) => {
         if (lines.length === 0) {
            return buildIfixitCart({ cart: null, fallbackCurrencyCode: currencyCode });
         }
         const cart = queryClient.getQueryData<Cart>(cartKeys.cart);
         const newCart =
            (cart?.shopifyCartId
               ? await performAddToCart({
                    cartId: cart.shopifyCartId,
                    client: storefrontClient,
                    lines,
                 })
               : null) ??
            (await createCart({
               lines: lines.map(({ quantity, shopifyVariantId }) => ({
                  merchandiseId: shopifyVariantId,
                  quantity,
               })),
            }));
         return buildIfixitCart({ cart: newCart, fallbackCurrencyCode: currencyCode });
      },
      {
         onMutate: async ({ lines }: AddToCartInput) => {
            await queryClient.cancelQueries(cartKeys.cart);
            window.onbeforeunload = () =>
               "Some products are being added to the cart. Are you sure you'd like to cancel?";

            const previousCart = queryClient.getQueryData<Cart>(cartKeys.cart);

            queryClient.setQueryData<Cart | undefined>(cartKeys.cart, currentCart => {
               if (currentCart == null) {
                  return currentCart;
               }
               const updatedCart = lines.reduce(
                  (cart, line) => addLineItem(cart, line),
                  currentCart
               );

               updatedCart.lineItems.sort((a, b) => a.itemcode.localeCompare(b.itemcode));

               return updatedCart;
            });

            return { previousCart };
         },
         onError: (error, variables, context) => {
            queryClient.setQueryData<Cart | undefined>(cartKeys.cart, context?.previousCart);
            toast.closeAll();
            toast({
               id: 'add-to-cart-error',
               status: 'error',
               title: 'Oops! Something Went Wrong',
               description: 'Please try again. If the problem persists contact us.',
               isClosable: true,
               variant: 'subtle',
               position: 'bottom-right',
            });
         },
         onSuccess: (data, variables) => {
            trackPiwikShopifyAddToCart(variables, analyticsMessage);
            const event = convertAddToCartInputToAnalyticsItemEvent(variables);
            trackInAnalyticsAddToCart(event);
            const cart = queryClient.getQueryData<Cart>(cartKeys.cart);
            if (cart) {
               trackPiwikCartUpdate({
                  items: convertCartLineItemsToAnalyticsItem(cart.lineItems),
                  value: Number(cart.totals.price.amount),
                  currency: cart.totals.price.currencyCode,
               });
            }
         },
         onSettled: () => {
            window.onbeforeunload = () => {};
            queryClient.invalidateQueries(cartKeys.cart);
         },
      }
   );
   return mutation;
}

export async function performAddToCart({
   cartId,
   client,
   lines,
}: { client: StorefrontClient; cartId: string; lines: CartLineItem[] }) {
   const cart = await getCart(client, { cartId });
   const lineEdges = cart?.lines.edges ?? [];
   const linesToUpdate =
      cart == null
         ? []
         : lines
              .map(line => {
                 const existingLine = lineEdges.find(
                    ({ node }) => node.merchandise.id === line.shopifyVariantId
                 )?.node;
                 const existingQuantity = existingLine?.quantity ?? 0;
                 return existingLine?.id
                    ? {
                         id: existingLine.id,
                         merchandiseId: line.shopifyVariantId,
                         quantity: existingQuantity + line.quantity,
                      }
                    : null;
              })
              .filter(<T,>(line: T | null): line is T => line != null);
   const linesToAdd = lines
      .filter(line => !lineEdges.some(({ node }) => node.merchandise.id === line.shopifyVariantId))
      .map(({ shopifyVariantId, quantity }) => ({
         merchandiseId: shopifyVariantId,
         quantity,
      }));
   let newCart = cart;
   if (linesToUpdate.length > 0) {
      newCart = await updateCartLines(client, { cartId, lines: linesToUpdate });
   }
   if (linesToAdd.length > 0) {
      newCart = await addToCart(client, { cartId, lines: linesToAdd });
   }
   return newCart;
}

function addLineItem(cart: Cart, inputLineItem: CartLineItem): Cart {
   const currentLineItemIndex = cart.lineItems.findIndex(
      item => item.itemcode === inputLineItem.itemcode
   );
   const isAlreadyInCart = currentLineItemIndex >= 0;
   const updatedLineItems = [...cart.lineItems];
   if (isAlreadyInCart) {
      const currentLineItem = cart.lineItems[currentLineItemIndex];
      const updatedQuantity = currentLineItem.quantity + inputLineItem.quantity;
      updatedLineItems.splice(currentLineItemIndex, 1, {
         ...currentLineItem,
         quantity: updatedQuantity,
      });
   } else {
      updatedLineItems.push(inputLineItem);
   }
   const updateTotalPrice = Math.max(
      Number(cart.totals.price.amount) +
         inputLineItem.quantity * Number(inputLineItem.price.amount),
      0
   ).toFixed(2);
   return {
      ...cart,
      hasItemsInCart: true,
      isEmpty: false,
      lineItems: updatedLineItems,
      totals: {
         ...cart.totals,
         price: {
            ...cart.totals.price,
            amount: updateTotalPrice,
         },
         itemsCount: cart.totals.itemsCount + inputLineItem.quantity,
      },
   };
}
