import {
   type AnalyticsItemsEvent,
   convertCartLineItemsToAnalyticsItem,
   trackInPiwik,
} from '@ifixit/analytics';
import { AddToCartInput } from '../hooks/cart/use-add-to-cart';

export function trackPiwikShopifyAddToCart(
   { lines, analytics }: AddToCartInput,
   eventSpecification?: string
) {
   const itemcodeList = `${lines.map(line => line.itemcode).join(', ')}`;
   switch (analytics.type) {
      case 'product': {
         const event = `Add to Cart` + (eventSpecification ? ` - ${eventSpecification}` : '');
         const itemcodes =
            lines.length === 1 && lines[0].itemcode === analytics.currentItemCode
               ? analytics.currentItemCode
               : `${analytics.currentItemCode}/${itemcodeList}`;
         trackInPiwik({
            eventCategory: event,
            eventAction: `${event} - ${itemcodes}`,
            eventName: `${window.location.origin}${window.location.pathname}`,
         });
         break;
      }
      case 'buy-it-again': {
         const quantityList = `${lines.map(line => line.quantity).join(', ')}`;
         trackInPiwik({
            eventCategory: 'Add to Cart - Buy it Again',
            eventAction: itemcodeList,
            eventName: quantityList,
         });
         break;
      }
   }
}

export function convertAddToCartInputToAnalyticsItemEvent(
   input: AddToCartInput
): AnalyticsItemsEvent {
   return {
      items: convertCartLineItemsToAnalyticsItem(input.lines),
      value: input.lines.reduce((acc, item) => acc + Number(item.price.amount), 0),
      currency: input.lines[0]?.price.currencyCode,
   };
}
