import { SentryError } from '@ifixit/sentry';
import { createContext, useMemo, useContext } from 'react';
import type { PropsWithChildren } from 'react';
import { type Sdk as StorefrontClient } from './generated/sdk';
import { getClientShopifyStorefrontSdk } from './sdk';

export { type Sdk as StorefrontClient } from './generated/sdk';

const Context = createContext<{
   client: StorefrontClient;
   currencyCode: string;
   shopDomain: string;
   storeCode: string;
} | null>(null);

export type ShopifyStorefrontContextProviderProps = PropsWithChildren<{
   currencyCode: string;
   shopDomain: string;
   storeCode: string;
   storefrontAccessToken: string;
}>;

export function ShopifyStorefrontProvider({
   currencyCode,
   shopDomain,
   storeCode,
   storefrontAccessToken,
   children,
}: ShopifyStorefrontContextProviderProps) {
   const client = useMemo(
      () => ({
         client: getClientShopifyStorefrontSdk({ shopDomain, storefrontAccessToken }),
         currencyCode,
         shopDomain,
         storeCode,
      }),
      [currencyCode, shopDomain, storeCode, storefrontAccessToken]
   );
   return <Context.Provider value={client}>{children}</Context.Provider>;
}

export function useShopifyStorefrontClient() {
   const client = useContext(Context);
   if (client == null) {
      throw new SentryError(
         'useShopifyStorefrontClient must be used within a ShopifyStorefrontProvider'
      );
   }
   return client;
}
