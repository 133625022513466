import type { ProductVariantID } from '@ifixit/helpers';
import { captureException, SentryError } from '@ifixit/sentry';
import { GetCartQuery } from '@ifixit/shopify-storefront-client';
import type { Cart, CartLineItem } from '../types';

type QueryCart = NonNullable<GetCartQuery['cart']>;
type QueryLineItem = QueryCart['lines']['edges'][0]['node'];

type BuildIfixitCartProps = {
   cart: QueryCart | null;
   fallbackCurrencyCode: string;
};
export function buildIfixitCart({ cart, fallbackCurrencyCode }: BuildIfixitCartProps): Cart {
   if (cart == null) {
      return emptyCart(fallbackCurrencyCode);
   }
   const subtotalAmount = cart.cost.subtotalAmount;
   const currencyCode =
      subtotalAmount.currencyCode === 'XXX' ? fallbackCurrencyCode : subtotalAmount.currencyCode;
   const compareAtPriceAmount = cart.lines.edges.reduce((sum, { node: lineItem }) => {
      const amountPerQuantity =
         lineItem.cost.compareAtAmountPerQuantity ?? lineItem.merchandise.price;
      return sum + Number.parseFloat(amountPerQuantity.amount) * lineItem.quantity;
   }, 0);
   const discountAmount = compareAtPriceAmount - Number.parseFloat(subtotalAmount.amount);
   const compareAtPrice = {
      amount: compareAtPriceAmount.toFixed(2),
      currencyCode,
   };
   const discount = {
      amount: discountAmount,
      currencyCode,
   };
   return {
      hasItemsInCart: cart.totalQuantity > 0,
      isEmpty: cart.totalQuantity === 0,
      lineItems: cart.lines.edges.map(({ node }) => buildIfixitLineItem(node)),
      totals: {
         discount,
         itemsCount: cart.totalQuantity,
         price: {
            amount: subtotalAmount.amount,
            currencyCode,
         },
         compareAtPrice,
      },
      checkoutUrl: cart.checkoutUrl,
      shopifyCartId: cart.id,
   };
}

function buildIfixitLineItem(lineItem: QueryLineItem): CartLineItem {
   const variant = lineItem.merchandise;
   if (!variant.sku) {
      throw new SentryError('Shopify line item is missing SKU', { extra: { lineItem } });
   }
   const shopifyVariantId = variant.id as ProductVariantID;
   return {
      shopifyLineId: lineItem.id,
      itemcode: variant.sku,
      variantTitle: variant.title,
      shopifyVariantId,
      name: variant.product.title,
      internalDisplayName: variant.metafieldInternalDisplayName?.value,
      imageSrc: variant.image?.url,
      quantity: lineItem.quantity,
      maxToAdd: variant.quantityAvailable ?? undefined,
      price: lineItem.cost.amountPerQuantity,
      compareAtPrice: lineItem.cost.compareAtAmountPerQuantity ?? lineItem.merchandise.price,
      categories: parseAnalyticsDimensionsMetafield(variant.metafieldAnalyticsDimensions),
      url: `/products/${variant.product.handle}?=${shopifyVariantId}`,
   };
}

function parseAnalyticsDimensionsMetafield(
   metafield: { value: string } | null | undefined
): string[] | null {
   if (metafield == null) {
      return null;
   }
   try {
      return JSON.parse(metafield.value);
   } catch (error) {
      console.error('Failed to parse analytics dimensions metafield', error);
      captureException(
         new SentryError('Failed to parse analytics dimensions metafield', { extra: { error } })
      );
      return null;
   }
}

function emptyCart(fallbackCurrencyCode: string): Cart {
   return {
      hasItemsInCart: false,
      isEmpty: true,
      lineItems: [],
      totals: {
         discount: { amount: 0, currencyCode: fallbackCurrencyCode },
         itemsCount: 0,
         price: { amount: '0', currencyCode: fallbackCurrencyCode },
         compareAtPrice: { amount: '0', currencyCode: fallbackCurrencyCode },
      },
   };
}
